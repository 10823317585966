@media all and (min-width: 480px) {
  .Validate {
    padding: 60px 0;
  }

  .Validate form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Validate .page-header h1 {
  font-family: "Lobster", cursive;
  text-align: center;
}

.Validate .form-group {
  margin-bottom: 5px;
}

.Validate form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
  padding-left: 5px;
}

.checkbox-inline {
  font-size: 14px;
  /* padding-bottom: 10px; */
  color: #999;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

input[type="checkbox"] {
  /* Attempt to style checkbox (not working) */
  /* -webkit-appearance: none; */
  /* appearance: none; */
  /* height: 45px; */
  /* padding: 6px 12px; */
  /* font-size: 12px; */
  /* color: #8f96a9; */
  /* border:none; */
  /* border-radius: 2px; */
  /* background-color: #58BADE; */
  position: relative !important;
  padding: 8px;
  margin: 0;
  margin-right: 1rem;
}

.Validate .form-control {
  height: 45px;
  padding: 6px 12px;
  font-size: 16px;
  color: #8f96a9;
  border: none;
  border-radius: 2px;
}

.Validate .form-control.key {
  text-transform: lowercase;
}

.Validate .form-control.code {
  text-transform: uppercase;
}

.Validate .LoaderButton {
  width: 100%;
  color: #fff;
  background: #58bade;
  font-family: "Lobster", cursive;
  font-size: 26px; /* PIXEL FALLBACK */
  font-size: 2.6rem;
  line-height: 30px;
  text-align: center;
  border: none;
  border-radius: 2px;
  /* border-radius: 2px; */
}

.Validate .LoaderButton:hover {
  background: #58bade;
}

.Validate .LoaderButton:disabled {
  background: rgb(111, 190, 219);
  /* background: #8f96a9; */
}

.modal-close-button {
  /* background: red; */
  background: none;
  border: none;
  padding: 0;
  top: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
}

.modal-close-button:hover,
.modal-close-button:focus {
  background: none;
}

.modal-close-button:active {
  background: none !important;
  box-shadow: none !important;
}

.modal-close-button:hover > .glyphicon {
  color: red;
}

.modal-close-button .glyphicon {
  position: inherit;
  background: none;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
