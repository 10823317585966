.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.App .navbar-brand > img {
  padding: 1px 5px 0px 0px;
  height: 44px;
}
