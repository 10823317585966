.scans h1 {
  text-align: center;
  font-family: "Lobster", cursive;
}

.spinning.glyphicon {
  /* top: 2px; */
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

img {
  opacity: 0; /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

/* IE 6 */
/* * html #footer {
   position:absolute;
   top:expression((0-(footer.offsetHeight)+(document.documentElement.clientHeight ? document.documentElement.clientHeight : document.body.clientHeight)+(ignoreMe = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop))+'px');
} */

/* [title="Select"] {
  display: none !important;
} */

.image-select-modal-modal [title="Select"] {
  z-index: 1;
  position: relative;
  display: block;
}

.flexbox {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-react {
  outline: 0;
}

.video-react-menu-button {
  display: none;
}

.Gallery {
  height: 80%;
}

.gallery {
  width: 100%;
  /* height: 80vh; */
  /* overflow-y: scroll; */
  /* -webkit-overflow-scrolling: touch; */
  /* display: inline-block; */
  font-size: 8pt;
}

/* Modal Overlay */
.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rebeccapurple; */
  background-color: rgba(0, 0, 0, 0.918);

  /* Center modal box veritcally */
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal box that contains carousel */
.react-modal-modal {
  position: absolute;
  /* background-color: papayawhip; */
  background-color: rgba(255, 0, 0, 0);
  outline: 0;
  width: 100%;
  height: auto;
}

.image-select-modal-modal {
  position: absolute;
  background-color: #eeeff1;
  /* background-color: rgba(255, 0, 0, 0); */
  outline: 0;
  width: 90%;
  height: 80%;
  min-height: 350px;
  min-width: 320px;
  max-width: 500px;
  border-radius: 5px;
}

.selection-gallery {
  /* padding: 20px; */
  /* padding: 0 20px 20px 20px; */
  margin: 0 20px 0 20px;
  /* margin: 20px; */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  /* Firefox */
  height: -moz-calc(100% - 190px);
  /* WebKit */
  height: -webkit-calc(100% - 190px);
  /* Opera */
  height: -o-calc(100% - 190px);
  /* Standard */
  height: calc(100% - 190px);
  /* height: 80%; */
  position: relative;
  display: inline-block;
  /* background-color: red; */
  font-size: 8pt;
  /* width: 100%; */
  width: calc(100% - 40px);
}

/* .custom-overlay {
  height: 20px;
  color: white;
  z-index: 100;
} */

.selection-gallery .ReactGridGallery {
  height: 100%;
  position: relative;
}

.message-ta {
  margin: 0 20px 10px 20px;
}

.message-ta h5 {
  font-size: 1em;
  text-align: center;
}

.message-ta textarea {
  width: 100%;
  /* height: 80px; */
  resize: none;
  overflow: auto;
  border: 3px solid #cccccc;
  padding: 5px;
  font-size: 0.8em;
  /* font-family: Tahoma, sans-serif; */
  /* font-style: inherit; */
}

.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 35px;
  width: 100%;
  background: #eeeff1;
  display: flex;
  justify-content: center;
}

@media (max-width: 1200px) {
  .social {
    max-width: 970px !important;
  }
}
@media (max-width: 992px) {
  .social {
    max-width: 750px !important;
  }
}
/* Additional to style.css */
.social {
  padding: 5px 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1170px;
}
.fb-share-btn {
  background-color: #3b5998;
  color: #fff;
  border-color: #3b5998;
  width: 68px;
  float: right;
  margin: 0 2px 0 0;
  padding: 2px 2px 2px 2px;
  font-size: 10pt;
}

.fa-facebook-official {
  font-size: 1.2em;
  margin: 0 2px;
}

.post-btn {
  margin: 0 20px 20px 20px;
}

.fb-post-btn {
  width: 100%;
  background-color: #3b5998;
  color: #fff;
  border-color: #3b5998;
}

/* big buttons, no circle */
.flickity-prev-next-button {
  width: 60px;
  height: 60px;
  background: transparent;
}
.flickity-prev-next-button:hover {
  background: transparent;
}
/* arrow color */
.flickity-prev-next-button .arrow {
  fill: white;
}
.flickity-prev-next-button.no-svg {
  color: white;
}
/* hide disabled button */
/* .flickity-prev-next-button:disabled {
  display: none;
} */

.carousel-cell {
  width: 100%; /* full width */
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Center cell image */
.carousel-cell img {
  display: block;
  margin: auto;
  height: auto;
  max-width: 100%;
}

.medialoader-loading {
  font-size: 50px;
}

.medialoader-failed .content,
.medialoader-loading .content {
  display: none;
}

.medialoader-failed {
  /* color: white; */
  font-size: 50px;

  opacity: 0; /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
