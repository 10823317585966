/* Modal Overlay */
.tos-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rebeccapurple; */
  background-color: rgba(0, 0, 0, 0.753);

  /* Center modal box veritcally */
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal box that contains carousel */
.tos-modal-modal {
  position: absolute;
  background-color: #eeeff1;
  /* background-color: rgba(255, 0, 0, 0); */
  outline: 0;
  width: 90%;
  height: 80%;
  max-width: 500px;
  border-radius: 5px;
}

.tos {
  padding: 20px;
  /* margin: 20px; */
  overflow-y: auto;
  /* Firefox */
  height: -moz-calc(100% - 20px);
  /* WebKit */
  height: -webkit-calc(100% - 20px);
  /* Opera */
  height: -o-calc(100% - 20px);
  /* Standard */
  height: calc(100% - 20px);
  /* position: inherit; */
  display: inline-block;
  /* background-color: red; */
  font-size: 8pt;
}

/* Point/Sub point alignment */
.tos > h5,
.tos > h6 {
  display: flex;
  justify-content: center;
  font-weight: bold;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol li > p {
  margin: 0;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

ol li > ul {
  list-style-type: lower-alpha;
  padding-left: 20px;
}
