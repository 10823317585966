/* --------------------------------------
=========================================
Flat Vault - Multipurpose Landing Page
Version: 1.0
Designed By: CreativeCary
=========================================
-----------------------------------------

1. GLOABL-CSS
	1.1 GENERAL-CSS
	1.2 TYPOGRAPHY

2. MASTER COLOR SETTINGS (TO CHANGE ENTIRE THEME)
	2.1 BACKGROUND COLOR SETTINGS
	2.2 BORDER COLOR SETTINGS
	2.3 TEXT COLOR SETTINGS 

3. PRELOADER
	3.1 LOADING
	3.2 LOADING ANIMATION
	
4. SECTIONS-CSS
	4.1 SECTION WHITE
	4.2 SECTION RED
	
5. HEADER-SECTION
	5.1 HEADER LEFT
	5.2 HEADER RIGHT

6. ADVERTISED-SECTION
	6.1 BASIC STYLE
	6.2 ARROW LEFT AND DOWN

7. FEATURE-SECTION
	7.1 BASIC STYLE
	7.2 FEATURE LIST
	7.3 PHONE IMAGE
	
8. DESIGNE-SECTION

9. BENIFITS-SECTION

10. CLIENT-SECTION

11. PRICE-SECTION
	11.1 BASIC STYLE
	11.2 PRICE COLUMN
	11.3 PRICE RED COLUMN
	
12. SUBSCRIBE-SECTION
	12.1 BASIC STYLE
	12.2 NEWSLETTER
	12.3 SOCIAL MEDIA

13. FOOTER-SECTION

14. RESPONSIVE FIXES
	14.1 FOR MAXIMUM WIDTH 992PX
	14.2 FOR MAXIMUM WIDTH 768PX
	14.3 FOR MAXIMUM WIDTH 380PX

	
-----------------------------------------*/

/* --------------------------------------
=========================================
   1. GLOABL - CSS
=========================================
-----------------------------------------*/

/*---------------------------------------
   1.1 IMPORT GOOGLE WEBFONT               
-----------------------------------------*/

@import url("//fonts.googleapis.com/css?family=Lobster|Open+Sans");

/*---------------------------------------
   1.2 GENERAL - CSS               
-----------------------------------------*/

body {
  background: #eeeff1;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 16px; /* PIXEL FALLBACK */
  font-size: 1.6rem;
  line-height: 1.5;
  color: #8f96a9;
}

/* .row {
	margin: 0 auto;
	max-width: 640px;
} */

.mobile-media-heading {
  display: none;
}

/* We’ll also tweak the styles of the form fields so that the mobile browser does not zoom in on them on focus. 
   We just need them to have a minimum font size of 16px to prevent the zoom. */
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
/* We are also setting the width of the input type file to prevent the page on mobile from overflowing and
   adding a scrollbar. */
input[type="file"] {
  width: 100%;
}

button {
  color: #fff;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  width: 250px;
  border: none;
}

button:hover {
  background: #58bade;
}
iframe {
  border: none;
}

/*---------------------------------------
   1.3 TYPOGRAPHY               
-----------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: 'lato', sans-serif; */
  font-family: "Lobster", cursive;
  color: #474c56;
}

h1 {
  font-size: 48px; /* PIXEL FALLBACK */
  font-size: 4.8rem;
}

h2 {
  font-size: 36px; /* PIXEL FALLBACK */
  font-size: 3.6rem;
  /*font-weight:bold;*/
  margin-top: 0;
}

h3 {
  font-size: 22px; /* PIXEL FALLBACK */
  font-size: 2.2rem;
  /*font-weight: bold;*/
}
h4 {
  font-size: 26px; /* PIXEL FALLBACK */
  font-size: 2.6rem;
  font-weight: bold;
  /*text-transform:uppercase;*/
}

/* --------------------------------------
=========================================
   2. MASTER COLOR SETTINGS
=========================================
-----------------------------------------*/

/*---------------------------------------
   2.1 BACKGROUND COLOR SETTINGS               
-----------------------------------------*/
#object,
h2.form-title,
button,
.submit-btn,
.advertised.red,
.feature-bullet,
.red-bg,
.price-bg.red,
.separator.red,
.price-bg .price-seprator.red,
.newsletter-button,
ul.social-icons li a {
  background: #58bade;
}

button:hover,
.submit-btn:hover,
.newsletter-button:hover,
ul.social-icons li a:hover {
  background: #58bade;
}

/*---------------------------------------
   2.2 BORDER COLOR SETTINGS               
-----------------------------------------*/
.logo img {
  border-color: #58bade;
}
.vertical-line {
  border-color: #58bade !important;
}
.arrow-left {
  border-left: 10px solid #58bade !important;
}
.arrow-down {
  border-top: 25px solid #58bade !important;
}

/*---------------------------------------
   2.3 TEXT COLOR SETTINGS               
-----------------------------------------*/

.price-circle span,
a {
  color: #58bade;
}

a:hover {
  color: #58bade;
}

/* --------------------------------------
=========================================
   3. PRELOADER
=========================================
-----------------------------------------*/

/*---------------------------------------
   3.1 LOADING               
-----------------------------------------*/

#loading {
  background: #eeeff1;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
}
#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}
#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 200px;
  width: 200px;
  margin-top: -100px;
  margin-left: -100px;
}
#object {
  width: 30px;
  height: 30px;
  -webkit-animation: animate 1s infinite ease-in-out;
  animation: animate 1s infinite ease-in-out;
  margin-right: auto;
  margin-left: auto;
  margin-top: 60px;
}

/*---------------------------------------
   3.2 LOADING ANIMATION               
-----------------------------------------*/

@-webkit-keyframes animate {
  0% {
    -webkit-transform: perspective(160px);
  }
  50% {
    -webkit-transform: perspective(160px) rotateY(-180deg);
  }
  100% {
    -webkit-transform: perspective(160px) rotateY(-180deg) rotateX(-180deg);
  }
}

@keyframes animate {
  0% {
    transform: perspective(160px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(160px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
    -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
  }
  100% {
    transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
    -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
  }
}

/* --------------------------------------
=========================================
   4. SECTIONS-CSS
=========================================
-----------------------------------------*/

/*---------------------------------------
   4.1 SECTION WHITE               
-----------------------------------------*/

section {
  margin-top: 30px;
  text-align: center;
}

.white-bg {
  width: 100%;
  background: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
  float: left;
}

.separator {
  background: #fff;
  display: inline-block;
  height: 4px;
  margin-top: 21px;
  width: 80px;
}

section p {
  color: #8f96a9;
  margin: 23px 0 0;
}

/*---------------------------------------
   4.2 SECTION RED               
-----------------------------------------*/

.red-bg {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  float: left;
}

.red-bg h2 {
  color: #ffffff;
  font-size: 36px; /* PIXEL FALLBACK */
  font-size: 3.6rem;
  font-weight: bold;
}
.red-bg p {
  color: #fff;
}

/* --------------------------------------
=========================================
   5. NAVBAR-SECTION
=========================================
-----------------------------------------*/
.navbar-toggle {
  width: 43px;
  /* background-image: -webkit-linear-gradient(top,#fff 0,#fff 100%) */
  border-radius: 2px;
}

.navbar-default {
  /* background-image: -webkit-linear-gradient(top,#fff 0,#fff 100%) */
  background-image: none;
  background-color: #fff;
  border-radius: 0;
}

/* --------------------------------------
=========================================
   5. HEADER-SECTION
=========================================
-----------------------------------------*/
.jumbotron {
  /* background: url(../img/texture.jpg); */
  /*background: #8E9CA3;*/
  /*min-height: 700px;*/
  min-height: 200%;
  margin-bottom: 0;
}

/*---------------------------------------
   5.1 HEADER LEFT               
-----------------------------------------*/
#header-left {
  padding: 0 0 0 10px;
}

.logo {
  margin: 79px 0 0;
}

.vertical-line {
  border-left: 4px solid;
  border-color: #ff0000;
  margin: 91px 0 0;
  padding: 0 0 0 19px;
}

.jumbotron h1 {
  font-size: 48px; /* PIXEL FALLBACK */
  font-size: 4.8rem;
  color: #fff;
  margin-top: 0;
}

.jumbotron h2 {
  font-size: 28px; /* PIXEL FALLBACK */
  font-size: 2.8rem;
  font-weight: normal;
  color: #fff;
  margin-bottom: 0;
}

.jumbotron p {
  color: #fff;
  float: left;
  font-size: 18px; /* PIXEL FALLBACK */
  font-size: 1.8rem;
  margin: 45px 0;
}

button.view-detail {
  width: 250px;
}

/*---------------------------------------
   5.2 HEADER RIGHT               
-----------------------------------------*/
h2.form-title {
  color: #fff;
  padding: 36px 0;
  text-align: center;
  position: relative;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-top: 25px solid #58bade;
  bottom: -17px;
  left: 45%;
  position: absolute;
}

/* .form-bg {
	background: url("../img/form_bg.png") center bottom;
	padding: 50px 20px;
} */

.form-control {
  height: 45px;
  padding: 6px 12px;
  font-size: 14px;
  color: #8f96a9;
  border: none;
  border-radius: 2px;
}

/* .form-group-lg {
  height: 45px;
  padding: 6px 12px;
  font-size: 14px;
  color: #8f96a9;
  border:none;
  border-radius: 2px;
} */

.submit-btn {
  width: 100%;
  color: #fff;
  font-family: "Lobster", cursive;
  font-size: 26px; /* PIXEL FALLBACK */
  font-size: 2.6rem;
  line-height: 50px;
  text-align: center;
  border: none;
  /* border-radius: 2px; */
}

.error {
  background: #58bade;
  color: #fff;
  text-align: center;
  line-height: 50px;
  margin-bottom: 15px;
}
.success {
  background: #42b089;
  color: #fff;
  text-align: center;
  line-height: 50px;
  margin-bottom: 15px;
}
/* --------------------------------------
=========================================
   6. ADVERTISED-SECTION
=========================================
-----------------------------------------*/

/*---------------------------------------
   6.1 BASIC STYLE               
-----------------------------------------*/
#advertised-section {
  background: #243842;
  padding: 50px 0;
  margin-top: 0;
}

.advertised {
  background: #374d5b;
  height: 75px;
}

.advertised.red {
  position: relative;
}

.advertised p {
  line-height: 73px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: #fff;
  width: 100%;
  margin: 0px;
  text-align: center;
}

/*---------------------------------------
   6.2 ARROW LEFT AND DOWN               
-----------------------------------------*/
.arrow-left {
  position: absolute;
  right: -10px;
  top: 23px;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 10px solid #58bade;
}

.arrow-down-2 {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 10px solid #58bade;
  top: 75px;
  left: 48%;
  position: absolute;
  display: none;
}

/* --------------------------------------
=========================================
   7. FEATURE-SECTION
=========================================
-----------------------------------------*/

/*---------------------------------------
   7.1 BASIC STYLE               
-----------------------------------------*/
#feature-section {
  text-align: left;
}

section .vertical-line {
  margin: 0;
}

/*---------------------------------------
   7.2 FEATURE LIST              
-----------------------------------------*/
.feature-list {
  padding: 0px;
  float: left;
  width: 100%;
  margin: 47px 0 0;
}

.feature-list li {
  list-style: none;
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.feature-bullet {
  border-radius: 50%;
  color: #fff;
  font-size: 24px; /* PIXEL FALLBACK */
  font-size: 2.4rem;
  font-weight: bold;
  height: 45px;
  float: left;
  line-height: 44px;
  text-align: center;
  width: 45px;
}

.feature-content {
  width: 70%;
  margin-left: 20px;
  float: left;
}

.feature-list h3 {
  margin: 0;
}

.feature-list p {
  margin: 0px;
}

/*---------------------------------------
   7.3 PHONE IMAGE              
-----------------------------------------*/
.phone-img {
  text-align: right;
}

/* --------------------------------------
=========================================
   8. DESIGN-SECTION
=========================================
-----------------------------------------*/
.video-img {
  margin-top: 20px;
  position: relative;
}

.video {
  height: 306px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 34px;
  width: 482px;
  z-index: 0;
}

.video iframe {
  width: 100%;
  height: 306px;
}
/* --------------------------------------
=========================================
   9. BENIFITS-SECTION
=========================================
-----------------------------------------*/
.benifit-icon {
  margin: 30px 0 0;
  text-align: center;
}

/* --------------------------------------
=========================================
   10. CLIENT SECTION
=========================================
-----------------------------------------*/
.client-img {
  float: left;
  margin: 0px 0 0 30px;
}

.client-saying {
  float: left;
  margin: 10px 0 0 25px;
  padding: 0 0 0 29px;
  width: 75%;
  text-align: left;
  border-left: 5px solid #fff;
}

.client-saying p {
  font-style: italic;
  margin: 0px;
}

.client-saying h3 {
  color: #fff;
  margin-bottom: 0;
}

/* --------------------------------------
=========================================
   11. PRICE-SECTION
=========================================
-----------------------------------------*/

/*---------------------------------------
   11.1 PRICE COLUMN              
-----------------------------------------*/
.price-bg {
  width: 100%;
  background: #fff;
  text-align: center;
  padding: 50px 0;
  float: left;
}

.price-circle {
  width: 165px;
  height: 165px;
  line-height: 160px;
  background: #ededed;
  border-radius: 50%;
  color: rgb(71, 77, 86);
  display: inline-block;
  font-size: 60px; /* PIXEL FALLBACK */
  font-size: 6rem;
  font-weight: bold;
  position: relative;
  margin-bottom: 20px;
}

.price-circle span {
  background: #ededed;
  border-radius: 50%;
  font-size: 30px; /* PIXEL FALLBACK */
  font-size: 3rem;
  font-weight: normal;
  height: 50px;
  left: -5px;
  line-height: 50px;
  margin: 16px 0 0;
  position: absolute;
  width: 50px;
}

.price-seprator {
  display: inline-block;
  height: 4px;
  margin: 20px 0 0;
  width: 78px;
}

ul.price-list {
  padding: 0px;
  margin: 13px 0 0;
  width: 100%;
}

ul.price-list li {
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
  color: #8f96a9;
  font-size: 18px; /* PIXEL FALLBACK */
  font-size: 1.8rem;
  line-height: 42px;
}

.price-btn {
  display: inline-block;
  margin-top: 37px;
  color: #fff;
}

/*---------------------------------------
   11.2 PRICE RED COLUMN               
-----------------------------------------*/
.price-bg.red .price-seprator {
  background: #fff;
}

.price-bg.red h4 {
  color: #fff;
}

.price-bg.red ul.price-list li {
  color: #fff;
}

.price-bg.red .price-btn {
  background: #fff;
  color: rgb(71, 77, 86);
}

.price-bg.red .price-btn:hover {
  background: #ededed;
}

.price-bg.red .price-circle span {
  background: #fff;
}

.price-bg.red .price-circle {
  background: #fff;
}
/* --------------------------------------
=========================================
   12. SUBSCRIBE-SECTION
=========================================
-----------------------------------------*/

/*---------------------------------------
   12.1 BASIC STYLE               
-----------------------------------------*/
#subscribe-bg {
  /* background: url(../img/texture.jpg); */
  /*background: #8E9CA3;*/
  padding: 50px 0;
}

#subscribe-bg h2 {
  color: #fff;
}

#subscribe-bg p {
  color: #fff;
}

/*---------------------------------------
   12.2 NEWSLETTER               
-----------------------------------------*/
.newsletter {
  display: inline-block;
  margin: 28px 0;
  width: 635px;
}

.newsletter-input {
  float: left;
  border: none;
  background: #fff;
  height: 50px;
  padding: 0 10px;
  width: 76%;
  color: #8f96a9;
  font-size: 14px;
}

.newsletter-button {
  font-size: 15px;
  color: #fff;
  border: none;
  width: 24%;
  height: 50px;
  float: right;
}

.newsletter-button:hover {
  background: #58bade;
}

#newsletter-error {
  background: #58bade;
  color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  display: none;
}

#newsletter-success {
  background: #42b089;
  color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  display: none;
}

/*---------------------------------------
   12.3 SOCIAL MEDIA              
-----------------------------------------*/

.social {
  width: 100%;
  float: left;
}

.social p {
  color: #fff;
  width: 100%;
}

ul.social-icons {
  display: inline-block;
  padding: 0px;
  margin-top: 10px;
}

ul.social-icons li {
  display: inline-block;
  list-style: none;
  margin: 0 10px;
}

ul.social-icons li a {
  float: left;
  height: 40px;
  width: 40px;
}
/* --------------------------------------
=========================================
   13. FOOTER-SECTION
=========================================
-----------------------------------------*/
.copyright-section {
  background: #17262d;
  height: 100px;
}

.copyright-section p {
  width: 100%;
  padding-top: 45px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  margin: 0px;
}

/* --------------------------------------
=========================================
   14. RESPONSIVE FIXES
=========================================
-----------------------------------------*/

/*---------------------------------------
   14.1 FOR MAXIMUM WIDTH 992PX              
-----------------------------------------*/

@media (max-width: 1200px) {
  /* .social {
		max-width: 970px;
	} */

  .logo {
    width: 100%;
    /*margin: 0 15% 0 15%;*/
    margin-right: 15%;
    margin-left: 15%;
  }

  /*.logo img {
		border-bottom: 4px solid ;
		padding: 0 0 25px;
	}*/

  .vertical-line {
    border-left: 4px solid;
    border-color: #ff0000;
    /*margin: px 0 0;*/
    padding: 0 0 0 19px;
  }

  /*.jumbotron .vertical-line {
		border: none;
		margin: 25px 0 0;
		padding: 0;
	}*/

  body {
    background: #eeeff1;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px; /* PIXEL FALLBACK */
    font-size: 1.2rem;
    line-height: 1.5;
    color: #8f96a9;
  }

  .arrow-down {
    left: 38%;
  }

  .copyright-section p {
    padding-top: 35px;
  }

  .jumbotron {
    /* background: url(../img/texture.jpg); */
    /*background: #8E9CA3;*/
    /*min-height: 200%;*/
    margin-bottom: 0;
  }

  .jumbotron h1 {
    font-size: 4.8rem;
    text-align: center;
    margin: 0 0 8%;
  }
  .jumbotron h2 {
    font-size: 28px; /* PIXEL FALLBACK */
    font-size: 2.4rem;
    text-align: center;
    /*text-align: left;*/
  }

  .jumbotron p {
    font-size: 18px; /* PIXEL FALLBACK */
    font-size: 1.6rem;
    margin: 2% 2%;
  }

  /*button.view-detail {
		width: 100%;
	}
	.arrow-down {
		left:42%;
	}
	.detail-btn {
		margin: 0 0 50px;
	}

	.col-md-3 .advertised {
		margin:20px 0;
	}
	.arrow-left{
		display:none;
		}
	.arrow-down-2 {
		display:block;
	}
	.form-title h1 {
		font-size: 23px;
	}

	.features-holder {
		margin: 0;
		padding: 30px 10px 0;
	}

	.phone-img {
		margin-top: 30px;
		text-align: center;
	}

	.phone-img img {
		max-width: 270px;
		width: 90%;
	}

	.video-img > img {
		display: none;
	}

	.video {
		top: 11px;
		width: 90%;
		position: relative;
	}

	.video iframe {
		height: 300px;
		float: left;
	}

	#benifits-holder p {
		padding: 13px 15px 0;
	}

	.client-img {
		margin: 0;
		text-align: center;
		width: 100%;
		float:none;
	}

	.client-img > img {
		border-bottom: 4px solid #fff;
		padding: 0 0 25px;
	}

	.client-saying {
		border-left: 0 none;
		margin: 0;
		padding: 20px 10px 0;
		width: 100%;
		text-align: center;
		float:none;
	}

	.price-btn {
		width: 90%;
	}

	.price-bg{
		margin:10px 0;
		}

	.newsletter {
		width: 100%;
	}*/
}

/*---------------------------------------
   14.2 FOR MAXIMUM WIDTH 768PX              
-----------------------------------------*/

/*@media(max-width:769px) {

}*/

/* @media(max-width:992px) {

	.social {
		max-width: 970px;
	}
} */

@media (max-width: 768px) {
  .social {
    max-width: 750px;
  }
  body {
    background: #eeeff1;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px; /* PIXEL FALLBACK */
    font-size: 1.2rem;
    line-height: 1.5;
    color: #8f96a9;
  }

  #join-us-form {
    margin-top: 10%;
  }

  .media-heading {
    display: none;
  }

  .mobile-media-heading {
    display: inline-block;
  }

  .arrow-down {
    left: 38%;
  }

  .copyright-section p {
    padding-top: 35px;
  }

  .jumbotron h1 {
    font-size: 4.8rem;
    text-align: center;
    margin: 0 0 8%;
  }
  .jumbotron h2 {
    font-size: 28px; /* PIXEL FALLBACK */
    font-size: 2.4rem;
    text-align: center;
    /*text-align: left;*/
  }

  .jumbotron p {
    font-size: 18px; /* PIXEL FALLBACK */
    font-size: 1.6rem;
    margin: 2% 2%;
  }

  .logo {
    width: 50%;
    margin: 0 0 0;
    margin-left: 25%;
  }

  .logo img {
    border-bottom: 4px solid;
    padding: 0 0 25px;
  }

  .jumbotron .vertical-line {
    border: none;
    margin: 25px 0 0;
    padding: 0;
  }
}
